import Cookies from 'universal-cookie';

const cookies = new Cookies();

const URL_PARAM = 'ab';

class ABTestingService {
  constructor() {
    this.config = {
      enabled: false,
      current_experiment_key: null,
      current_experiment_ga_id: null,
      current_experiment_cookie_name: null,
      current_experiment_cookie_seconds: null,
      current_experiment_variants: [],
      current_experiment_variant_forced: null,
    };
  }

  init(config) {
    this.config = {
      ...config,
    };

    if (this.config.enabled !== true) {
      return;
    }

    // If we have the variant in URL we override it
    const variant = this.getVariantFromURL();
    if (variant) {
      this.config.current_experiment_variant_forced = variant;
      this.setCookie(variant);
      return;
    }

    if (this.hasCookie()) return;

    this.setCookie();
  }

  isEnabled(experimentId = null) {
    if (this.config.enabled !== true) {
      return false;
    }

    if (experimentId === null) {
      return true;
    }
    return experimentId === this.config.current_experiment_key;
  }

  getExperimentId() {
    if (this.isEnabled() !== true) {
      return null;
    }
    return this.config.current_experiment_key;
  }

  hasCookie() {
    const variant = cookies.get(this.config.current_experiment_cookie_name);
    return variant !== undefined;
  }

  getVariantFromURL() {
    const urlParams = new URLSearchParams(window.location.search);
    const variant = urlParams.get(URL_PARAM);

    if (variant !== null) {
      if (this.config.current_experiment_variants.indexOf(variant) !== -1) {
        return variant;
      }
    }

    return null;
  }

  getRandomVariant() {
    const minimum = 0;
    const maximum = this.config.current_experiment_variants.length - 1;
    const index = Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    return this.config.current_experiment_variants[index];
  }

  setCookie(variant = null) {
    const newVariant = variant ? variant : this.getRandomVariant();
    const now = new Date();
    const expiration = new Date(now.getTime() + (this.config.current_experiment_cookie_seconds * 1000));

    cookies.set(this.config.current_experiment_cookie_name, newVariant, { path: '/', expires: expiration });
  }

  getVariant() {
    if (this.config.enabled === false) {
      return null;
    }
    if (this.config.current_experiment_variant_forced) {
      return this.config.current_experiment_variant_forced;
    }
    return cookies.get(this.config.current_experiment_cookie_name);
  }

  getDataLayerExp() {
    const variant = this.getVariant();
    const index = this.config.current_experiment_variants.indexOf(variant);
    return this.config.current_experiment_ga_id + '.' + index;
  }

  getVariantOrDefault(defaultValue) {
    return this.getVariant() || defaultValue;
  }
}

window.abTestingService = new ABTestingService();
